@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Mulish';
  src: url('/fonts/Mulish-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Mulish';
  src: url('/fonts/Mulish-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Mulish';
  src: url('/fonts/Mulish-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Mulish';
  src: url('/fonts/Mulish-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Mulish';
  src: url('/fonts/Mulish-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Quicksand';
  src: url('/fonts/Quicksand-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Quicksand';
  src: url('/fonts/Quicksand-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Quicksand';
  src: url('/fonts/Quicksand-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Quicksand';
  src: url('/fonts/Quicksand-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Quicksand';
  src: url('/fonts/Quicksand-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

/*
  Utility for hiding scrollbar in scrollable elements
  e.g: WifSmallBanner
*/
@layer utilities {
  /* Hide scrollbar for Chrome, Safari, and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

body {
  @apply bg-ivory;
}

/* Strike Through Element

  This can be used to strike through an element.
  The righ and left on ::before and ::after is to determine
  the whitespace between the element and the lines.

  example can be seen in login.tsx
*/
.strike-through-element::before,
.strike-through-element::after {
  @apply inline-block h-[1px] relative align-middle w-1/2 bg-light-grey;
  content: '';
}

.strike-through-element::before {
  @apply right-6 -ml-[50%];
}

.strike-through-element::after {
  @apply left-6 -mr-[50%];
}

/* Wif Toast default style */

#wif-toast .Toastify__toast-container--top-center {
  @apply max-w-[500px] w-full px-4 h-[70px] pt-4;
}

#wif-toast .Toastify__toast.Toastify__toast-theme--colored {
  @apply h-[70px] mb-2 text-center rounded-full font-bold text-sm font-sans;
}

#wif-toast .Toastify__toast.Toastify__toast--info {
  @apply bg-white text-wif-black;
}

#wif-toast .Toastify__toast.Toastify__toast--error {
  @apply bg-wif-neutral-40 opacity-90;
}

.swiper-wrapper {
  @apply items-stretch;
}

/* End of Wif Toast */

/* Reset input type select styling */
select:disabled {
  opacity: 1;
}

/* Slider pagination */
.swiper-button-prev,
.swiper-button-next {
  @apply bg-white rounded-full !h-8 !w-8;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  @apply !text-sm text-black;
}
